import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import GetValuesForSecondStepGeneralFields from "../../../../../../Utils/ClaimModelUpdateFunctions/GetValuesForSecondStepGeneralFields";
import CustomInput from "../../../../../../Components/CustomInputs/CustomInput";
import { updateSecondStepGeneralsNestedFields } from "../../../../../../redux/reducers/claimReducer";
import { handleShowErrorMessage } from "../../../../../../redux/reducers/claimFieldErrorsReducer";
import { shouldRenderSpecField } from "../../../../../../redux/reducers/visibilityMatrixesReducer";
import WholeLineContainer from "../../../../../../Components/ReportFormTemplate/WholeLineContainer";
import { useScrollToTopErrorInput } from "../../../../../../CustomHooks/useScrollToTopErrorInput";

const NameOfAnotherInsuriensField: React.FC = () => {
	const dispatch = useDispatch();
	const fieldNameLowerCase = "nameOfAnotherInsuriens";
	const fieldNameUpperCase =
		fieldNameLowerCase.charAt(0).toUpperCase() + fieldNameLowerCase.slice(1);

	const {
		fieldValue,
		visibilityMatrixes,
		claimFieldErrorsMessages,
		dependencyValue,
	} = GetValuesForSecondStepGeneralFields(
		fieldNameLowerCase,
		"isAnotherInsurance"
	);

	useEffect(() => {
		if (!dependencyValue) {
			dispatch(
				updateSecondStepGeneralsNestedFields({
					value: "",
					field: fieldNameLowerCase,
				})
			);
		}
	}, [dependencyValue]);

	const ref = React.useRef<HTMLDivElement>(null);
	useScrollToTopErrorInput(ref, fieldNameUpperCase);
	return (
		<WholeLineContainer
			dependencyValue={
				shouldRenderSpecField(
					"GeneralFields",
					fieldNameUpperCase,
					visibilityMatrixes
				) && (dependencyValue as boolean)
			}
		>
			<CustomInput
				labelText="Uveďte pojišťovnu/y"
				placeholderText="Uveďte název pojišťovny/en"
				value={(fieldValue as string) || ""}
				onChange={(e) => {
					dispatch(
						updateSecondStepGeneralsNestedFields({
							value: e.target.value,
							field: fieldNameLowerCase,
						})
					);
				}}
				validationText={handleShowErrorMessage(
					fieldNameUpperCase,
					claimFieldErrorsMessages
				)}
				inputRef={ref}
			/>
		</WholeLineContainer>
	);
};

export default NameOfAnotherInsuriensField;
